/**
 * Get the title, description and field name of an error returned from the api
 * @param {Error} error - Axios error object
 * @returns {{ title: string, description: string, fieldName: string, isCustom: boolean }} Error title, description, field and if it is a custom error
 */
export const getCustomErrorMessage = error => {
  const customError = error?.response?.data?.data?.errors

  if (!customError) return { isCustom: false }

  for (const fieldName in customError) {
    const { title, description } = customError[fieldName] ?? {}

    return { title, description, fieldName, isCustom: Boolean(title) }
  }
}
