import { RESPONSE_TYPES } from 'constants/questions'

export const getDefaultInstructions = responseTypeId => {
  const INSTRUCTION = { instruction: '' }
  const INSTRUCTION_WITH_EXPLANATION = { instruction: '', explanation: '' }
  const INSTRUCTION_WITH_EMPTY_ANSWER = { instruction: '', explanation: '', answer: '' }

  if (
    [RESPONSE_TYPES.multipleChoiceWithoutAnswer, RESPONSE_TYPES.multipleChoiceWithJustification].includes(
      responseTypeId
    )
  ) {
    return Array.from({ length: 2 }).map(() => ({ ...INSTRUCTION }))
  }

  if (responseTypeId === RESPONSE_TYPES.discursiveWithInstructions) {
    return Array.from({ length: 2 }).map(() => ({ ...INSTRUCTION_WITH_EXPLANATION }))
  }

  if (responseTypeId === RESPONSE_TYPES.trueOrFalse) {
    return Array.from({ length: 1 }).map(() => ({ ...INSTRUCTION_WITH_EMPTY_ANSWER }))
  }

  if (responseTypeId === RESPONSE_TYPES.sumAnswer) {
    return Array.from({ length: 2 }).map(() => ({ ...INSTRUCTION_WITH_EMPTY_ANSWER }))
  }

  if (responseTypeId === RESPONSE_TYPES.multipleChoiceWithAnswer) {
    return Array.from({ length: 2 }).map(() => ({ ...INSTRUCTION_WITH_EXPLANATION }))
  }

  if (responseTypeId === RESPONSE_TYPES.multipleChoiceWithFourOptions) {
    return Array.from({ length: 4 }).map(() => ({ ...INSTRUCTION_WITH_EXPLANATION }))
  }

  if (responseTypeId === RESPONSE_TYPES.multipleChoiceWithFiveOptions) {
    return Array.from({ length: 5 }).map(() => ({ ...INSTRUCTION_WITH_EXPLANATION }))
  }
}
