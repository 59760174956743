import React from 'react'
import styled from 'styled-components'

const Prefix = styled.span`
  display: block;
  margin-right: 4px;
  white-space: nowrap;
`

export const prefixListItemWithLetters = index => (
  <Prefix>
    {String.fromCharCode(97 + index)}
    {') '}
  </Prefix>
)

export const prefixListItemWithAnswer = answer => (
  <Prefix>
    {answer}
    {') '}
  </Prefix>
)

export const prefixListItemWithEmptyParenthesis = index => (
  <Prefix>
    {String.fromCharCode(97 + index)}
    {' ( ) '}
  </Prefix>
)

export const prefixListItemWithParenthesisAndBoolean = (index, boolean) => (
  <Prefix>
    {String.fromCharCode(97 + index)} <strong>( {boolean ? 'V' : 'F'} ) </strong>
  </Prefix>
)
