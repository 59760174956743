import './wdyr.js'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'fontsource-roboto'

import React from 'react'
import ReactDOM from 'react-dom'
import { ReactQueryCacheProvider, QueryCache } from 'react-query'
import { FirebaseAppProvider } from 'reactfire'

import App from './App'

import { BrowserRouter } from 'react-router-dom'

import { config } from './config/firebase'

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const firebaseConfig = location.hostname === 'questoesdeaaz.com.br' ? config.prd : config.hml

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReactQueryCacheProvider>
  </FirebaseAppProvider>,
  document.getElementById('root')
)
