import { eachYearOfInterval, getYear } from 'date-fns'
import { v4 as uuidV4 } from 'uuid'

export const RESOURCES = [
  {
    label: 'Interna',
    valueName: 'internal',
    value: '0'
  },
  {
    label: 'Externa',
    valueName: 'external',
    value: '1'
  }
]

export const VERSIONS = [
  { label: 'Original', value: 'original' },
  { label: 'Adaptada', value: 'adaptada' }
]

export const DIFFICULTIES = [
  { label: 'Fácil', value: 'easy' },
  { label: 'Média', value: 'medium' },
  { label: 'Difícil', value: 'hard' }
]

export const CREATION_YEAR = eachYearOfInterval({
  start: new Date(1962, 1, 1),
  end: new Date(Date.now() + 5 * 31 * 24 * 60 * 60 * 1000)
})
  .map(item => ({
    label: String(getYear(item)),
    value: String(getYear(item))
  }))
  .sort((a, b) => b.label - a.label)

export const APPLICATIONS = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' }
]

export const RESPONSE_TYPES = {
  discursive: 3,
  discursiveWithInstructions: 4,
  essay: 11,
  discursiveWithoutGrade: 13,
  multipleChoiceWithFourOptions: 6,
  multipleChoiceWithFiveOptions: 7,
  multipleChoiceWithoutAnswer: 14,
  multipleChoiceWithAnswer: 16,
  multipleChoiceWithJustification: 15,
  trueOrFalse: 2,
  sumAnswer: 5,
  informative: 12
}

export const INSTRUCTION = {
  instruction: '',
  explanation: ''
}

export const INSTRUCTIONS = {
  [RESPONSE_TYPES.multipleChoiceWithJustification]: [...new Array(2)].map(() => ({ id: uuidV4(), ...INSTRUCTION })),
  [RESPONSE_TYPES.multipleChoiceWithoutAnswer]: [...new Array(2)].map(() => ({ id: uuidV4(), ...INSTRUCTION })),
  [RESPONSE_TYPES.multipleChoiceWithAnswer]: [...new Array(2)].map(() => ({ id: uuidV4(), ...INSTRUCTION })),
  [RESPONSE_TYPES.multipleChoiceWithFourOptions]: [...new Array(4)].map(() => ({ id: uuidV4(), ...INSTRUCTION })),
  [RESPONSE_TYPES.multipleChoiceWithFiveOptions]: [...new Array(5)].map(() => ({ id: uuidV4(), ...INSTRUCTION })),
  [RESPONSE_TYPES.discursiveWithInstructions]: [...new Array(2)].map(() => ({ id: uuidV4(), ...INSTRUCTION })),
  [RESPONSE_TYPES.trueOrFalse]: [{ id: uuidV4(), ...INSTRUCTION }],
  [RESPONSE_TYPES.sumAnswer]: [...new Array(2)].map(() => ({ id: uuidV4(), ...INSTRUCTION }))
}

export const SOURCE_TYPES = [
  { label: 'Interna', value: 'internal' },
  { label: 'Externa', value: 'external' }
]

export const IS_ORIGINAL_OPTIONS = [
  { label: 'Original', value: true },
  { label: 'Adaptada', value: false }
]

export const EMPTY_QUESTION_VERSION = {
  id: 'novo',
  instructions: [],
  video_code: '',
  response_type_id: null,
  instruction: '',
  estimated_time: '',
  difficulty: '',
  is_original: null
}

export const QUESTION = '@lex-content/question'
