export const getObject = key => {
  const object = JSON.parse(sessionStorage.getItem(key))
  if (typeof object === 'object' && !Array.isArray(object) && object !== null) {
    return object
  }
  return {}
}

export const setObject = (key, object) => {
  if (typeof object === 'object' && !Array.isArray(object) && object !== null) {
    sessionStorage.setItem(key, JSON.stringify(object))
  }
}

export const removeObject = key => {
  sessionStorage.removeItem(key)
}
