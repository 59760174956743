import React, { lazy } from 'react'
import { Loader } from '@conexia-az/az-components'

import { useUser } from 'context/user-context'

const AuthenticatedApp = lazy(() => import('./routes/AuthenticatedApp'))
const UnauthenticatedApp = lazy(() => import('./routes/UnauthenticatedApp'))

const Routes = () => {
  const { user, isLoading } = useUser()

  if (isLoading) return <Loader />
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

export default Routes
