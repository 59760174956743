export const matchVideoUrl = (url, platform) => {
  let match
  switch (platform) {
    case '1':
      match = url.match(/[^=]*$/)
      break
    case '2':
      match = url.match(/[^/]*$/)
      break
  }
  return match ? match[0] : false
}

export const validateFilterValue = (filters, options, key, value = '') =>
  options.find(option => String(option.value) === String(filters[key])) ? filters[key] : value

export const testRegexes = (regexes, value) => regexes.map(regex => regex.test(value))
