import axios from 'axios'

const __API__ = process.env.REACT_APP_API_GATEWAY + '/bcauthusers'

const defaultOptions = {
  baseURL: __API__
}

const instance = axios.create(defaultOptions)

export default instance
