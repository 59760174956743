import React from 'react'

import { UserProvider } from './user-context'
import { SchoolYearsProvider } from './school-years'
import { GradesProvider } from './grades'

const AppProviders = ({ children }) => (
  <UserProvider>
    <SchoolYearsProvider>
      <GradesProvider>{children}</GradesProvider>
    </SchoolYearsProvider>
  </UserProvider>
)

export default AppProviders
