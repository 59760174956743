import React, { Suspense } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import { Loader, AlertProvider } from '@conexia-az/az-components'
import { ReactQueryDevtools } from 'react-query-devtools'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { getPerformance } from 'firebase/performance'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'
import { useFirebaseApp, PerformanceProvider, useInitRemoteConfig, RemoteConfigProvider } from 'reactfire'

import AppProviders from 'context'
import Routes from './Routes'

import Theme from 'theme'

import 'sanitize.css/sanitize.css'
import 'components/TextEditor/reset.css'

moment.locale('pt-br')

const App = () => {
  const firePerformanceInstance = getPerformance(useFirebaseApp())
  const { status, data: remoteConfigInstance } = useInitRemoteConfig(async firebaseApp => {
    const remoteConfig = getRemoteConfig(firebaseApp)
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 3600000
    }

    await fetchAndActivate(remoteConfig)
    return remoteConfig
  })

  if (status === 'loading') {
    return null
  }

  return (
    <PerformanceProvider sdk={firePerformanceInstance}>
      <RemoteConfigProvider sdk={remoteConfigInstance}>
        <AppProviders>
          <Theme>
            <Helmet titleTemplate='Rede AZ | %s' />
            <AlertProvider>
              <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                <Suspense fallback={<Loader />}>
                  <Routes />
                </Suspense>
              </MuiPickersUtilsProvider>
            </AlertProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Theme>
        </AppProviders>
      </RemoteConfigProvider>
    </PerformanceProvider>
  )
}

export default App
