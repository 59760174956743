export const translatePermissionModule = permissionModule => {
  switch (permissionModule) {
    case 'instruments': {
      return 'Instrumentos'
    }

    case 'images': {
      return 'Imagens'
    }

    case 'questions': {
      return 'Questões'
    }

    case 'videos': {
      return 'Vídeos'
    }

    case 'templates': {
      return 'Modelos de Instrumentos'
    }

    case 'pendencies': {
      return 'Pendências de Vídeo'
    }

    case 'roles': {
      return 'Papéis'
    }

    case 'users': {
      return 'Usuários'
    }

    default: {
      return permissionModule
    }
  }
}

export const translatePermissionLevel = permisionLevel => {
  switch (permisionLevel) {
    case 'writer': {
      return 'Criar'
    }

    case 'reader': {
      return 'Listar'
    }

    default: {
      return permisionLevel
    }
  }
}
