import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { RESOURCES, RESPONSE_TYPES } from 'constants/questions'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
    notType: 'Campo obrigatório'
  },
  string: {
    max: 'Máximo de ${max} caracteres'
  }
})

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
        message: 'Campo obrigatório'
      })
    }

    if (this.parent.filter(v => v !== value).some(v => v[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${this.path}.${propertyName}`
      })
    }

    return true
  })
})

yup.addMethod(yup.array, 'notEmpty', function (_, message) {
  return this.test('empty', message, value => Array.isArray(value) && value.length !== 0)
})

export const STRING_LENGTH = 255

export const instrumentTemplateSchema = yupResolver(
  yup.object().shape({
    name: yup.string().trim().max(STRING_LENGTH).required(),
    status: yup.string().required(),
    templateResponseTypes: yup.array(yup.string()).required()
  })
)

export const instrumentSchema = yupResolver(
  yup.object().shape({
    name: yup.string().trim().max(STRING_LENGTH).required(),
    abbreviation: yup.string().trim().max(STRING_LENGTH),
    schoolYearId: yup.number().required(),
    gradeIds: yup.array(yup.object().shape({ value: yup.number(), label: yup.string() })).required(),
    templateId: yup.number().required()
  })
)

export const instrumentApplicationSchema = yupResolver(
  yup.object().shape({
    name: yup.string().trim().max(STRING_LENGTH).required(),
    applicationTypeId: yup.number().required(),
    singleApplication: yup.bool(),
    showTitle: yup.bool(),
    applicationValues: yup
      .array(
        yup.object().shape({ applicationConfigurationId: yup.number().required(), value: yup.string().required() })
      )
      .required()
  })
)

export const questionGroupSchema = yupResolver(
  yup.object().shape({
    minQuestions: yup.number().typeError('Ops, insira apenas números').required(),
    maxQuestions: yup
      .number()
      .typeError('Ops, insira apenas números')
      .when('minQuestions', minQuestions =>
        yup
          .number()
          .typeError('Ops, insira apenas números')
          .required()
          .min(minQuestions, 'O máximo de questões deve ser maior que o valor mínimo.')
      ),
    minVideos: yup
      .number()
      .typeError('Ops, insira apenas números')
      .when('maxQuestions', maxQuestions =>
        yup
          .number()
          .typeError('Ops, insira apenas números')
          .required()
          .max(maxQuestions, 'O número mínimo de questões com vídeos deve ser menor que o número máximo de questões.')
      )
  })
)

export const electiveSchema = yupResolver(
  yup.object().shape({
    name: yup.string().trim().max(STRING_LENGTH).required(),
    questionGroupIds: yup.array(
      yup.object().uniqueProperty('value', 'Não é possível ter disciplinas iguais em um grupo de opcionais')
    )
  })
)

export const instrumentExportSchema = yupResolver(
  yup.object().shape({
    format: yup.string().required(),
    questionGroupId: yup.number().required(),
    type: yup.string().required()
  })
)

const typesWithRequiredAnswerKey = [String(RESPONSE_TYPES.discursive)]

const typesWithRequiredAnswer = [
  RESPONSE_TYPES.multipleChoiceWithFourOptions,
  RESPONSE_TYPES.multipleChoiceWithAnswer,
  RESPONSE_TYPES.multipleChoiceWithFiveOptions,
  RESPONSE_TYPES.sumAnswer
]

const typesWithRequiredInstruction = [
  RESPONSE_TYPES.multipleChoiceWithoutAnswer,
  RESPONSE_TYPES.multipleChoiceWithJustification,
  RESPONSE_TYPES.discursiveWithInstructions,
  RESPONSE_TYPES.multipleChoiceWithFourOptions,
  RESPONSE_TYPES.multipleChoiceWithFiveOptions,
  RESPONSE_TYPES.multipleChoiceWithAnswer,
  RESPONSE_TYPES.trueOrFalse,
  RESPONSE_TYPES.sumAnswer
]

const typesWithRequiredInstructionAnswer = [RESPONSE_TYPES.sumAnswer, RESPONSE_TYPES.trueOrFalse]

const instructionSchema = yup.object().shape({
  instruction: yup.string().test('instructionTest', null, function (value) {
    const { responseType } = this.from[1].value
    return !!value || !typesWithRequiredInstruction.includes(responseType)
  }),
  explanation: yup.string().test('explanationTest', null, function (value) {
    const { responseType } = this.from[1].value
    return !!value || responseType !== RESPONSE_TYPES.discursiveWithInstructions
  }),
  answer: yup.string().test('answerTest', null, function (value) {
    const { responseType } = this.from[1].value
    return !!value || !typesWithRequiredInstructionAnswer.includes(responseType)
  })
})

export const questionSchema = yupResolver(
  yup.object().shape({
    theme: yup.string().required(),
    groupIds: yup.string().required(),
    themeIds: yup.string().required(),
    questionVersions: yup.array().of(
      yup.object().shape({
        answer: yup.number().when('responseType', {
          is: responseType => typesWithRequiredAnswer.includes(responseType),
          then: yup.number().required()
        }),
        responseType: yup.string().required(),
        command: yup.string().required(),
        answerKey: yup.string().when('responseType', {
          is: responseType => typesWithRequiredAnswerKey.includes(responseType),
          then: yup.string().required()
        }),
        instructions: yup.array().of(instructionSchema),
        commandForJustification: yup.string().when('responseType', {
          is: responseType => RESPONSE_TYPES.multipleChoiceWithJustification == responseType,
          then: yup.string().required()
        })
      })
    ),
    resourceType: yup.string().required(),
    author: yup.object().when('resourceType', {
      is: RESOURCES[0].value,
      then: yup.object().required()
    }),
    externalSource: yup.object().when('resourceType', {
      is: RESOURCES[1].value,
      then: yup.object().required()
    }),
    is_reserved: yup.string().required()
  })
)

export const questionPresetSchema = yupResolver(
  yup.object().shape({
    name: yup.string().required()
  })
)

export const imageSchema = yupResolver(
  yup.object().shape({
    description: yup.string().required(),
    file: yup.mixed().required()
  })
)

export const duplicateInstrumentSchema = yupResolver(
  yup.object().shape({
    name: yup.string().required(),
    schoolYearId: yup.string().required()
  })
)

export const videoSchema = yupResolver(
  yup.object().shape({
    code: yup.string().required(),
    url: yup.string().required()
  })
)

export const supportTextSchema = yupResolver(
  yup.object().shape({
    supportText: yup.string().required()
  })
)

export const questionConfigsSchema = yupResolver(
  yup.object({
    similar: yup.string().required(),
    different: yup.string().required()
  })
)

export const roleSchema = yupResolver(
  yup.object({
    name: yup.string().required(),
    permissions: yup.array().required('Você precisa habilitar pelo menos 1 permissão para o papel.')
  })
)

export const userSchema = yupResolver(
  yup.object().shape({
    name: yup.string().required(),
    lastname: yup.string().required(),
    username: yup
      .string()
      .required()
      .matches(/^([a-z]|@|-|_)+$/gi, 'Insira apenas letras e/ou os símbolos @ _ -'),
    email: yup.string().required().email('Insira um e-mail válido'),
    roleId: yup.string().required()
  })
)

const passwordMatchesMessage = 'Sua senha não obedece a todos os critérios'

export const createPasswordSchema = yupResolver(
  yup.object().shape({
    password: yup
      .string()
      .min(8, passwordMatchesMessage)
      .matches(/[a-z]+/i, passwordMatchesMessage)
      .matches(/[0-9]+/, passwordMatchesMessage)
      .matches(/^[a-z0-9]+$/i, passwordMatchesMessage),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'A senha deve ser igual a digitada anteriormente')
  })
)

export const updateQuestionSchema = yupResolver(
  yup.object().shape({
    group_ids: yup.array().notEmpty(),
    theme_ids: yup.array().notEmpty(),
    is_reserved: yup.string().required(),
    question_source: yup.object().shape({
      source_type: yup.string().required(),
      author: yup.object().when('source_type', {
        is: type => type === 'internal',
        then: yup.object().required()
      }),
      source_external: yup.object().when('source_type', {
        is: type => type === 'external',
        then: yup.object().required()
      })
    })
  })
)

export const updateQuestionVersionSchema = yupResolver(
  yup.object().shape({
    is_original: yup.boolean().required(),
    estimated_time: yup.string(),
    response_type_id: yup.number().required(),
    instruction: yup.string().required(),
    answer: yup.string().when('response_type_id', {
      is: id => typesWithRequiredAnswer.includes(id),
      then: yup.string().required()
    }),
    instruction_justify: yup.string().when('response_type_id', {
      is: id => id === RESPONSE_TYPES.multipleChoiceWithJustification,
      then: yup.string().required()
    }),
    explanation: yup.string().when('response_type_id', {
      is: id => id === RESPONSE_TYPES.discursive,
      then: yup.string().required()
    }),
    instructions: yup.array().of(
      yup.object().shape({
        instruction: yup.string().test('instruction', null, function (value) {
          const id = this.from[1].value.response_type_id

          return Boolean(value) || !typesWithRequiredInstruction.includes(id)
        }),
        explanation: yup.string().test('explanation', null, function (value) {
          const id = this.from[1].value.response_type_id

          return Boolean(value) || id !== RESPONSE_TYPES.discursiveWithInstructions
        }),
        answer: yup.string().test('answer', null, function (value) {
          const id = this.from[1].value.response_type_id

          return typeof value === 'number' || Boolean(value) || !typesWithRequiredInstructionAnswer.includes(id)
        })
      })
    )
  })
)
