import usersApi from 'providers/usersProvider'
import usersNoAuthProvider from 'providers/usersNoAuthProvider'
import { sendFileToS3 } from './aws'

export const getPaginatedRoles = async (_, { page, limit = 10 }) => {
  const params = {
    page,
    limit
  }

  const rolesResponse = await usersApi.get('/roles/paginate', { params })

  return {
    data: {
      itemsTotal: rolesResponse.data.itemsTotal,
      totalPages: rolesResponse.data.totalPages,
      items: rolesResponse.data.items
    }
  }
}

export const createRole = payload => usersApi.post('/roles/register', payload)

export const updateRoleById = (id, payload) => usersApi.put(`/roles/${id}/update`, payload)

export const deleteRoleById = id => usersApi.delete(`/roles/${id}`)

export const getPermissions = async () => {
  const { data: permissions } = await usersApi.get('/permissions')

  return permissions
}

export const getUserPermissions = async () => {
  const { data: permissions } = await usersApi.get('/roles/permissions/check')

  return permissions
}

export const getRoleById = async (_, id) => {
  const { data: role } = await usersApi.get(`/roles/${id}`)

  return role
}

export const getRoles = async () => {
  const { data: roles } = await usersApi.get('/roles')

  return roles
}

export const getUsersWithAutocomplete = async search => {
  return usersApi.get('/users/paginate', {
    params: {
      name: search,
      limit: 5,
      page: 1
    }
  })
}

export const getFormattedUsers = async () => {
  const { data: users } = await usersApi.get('/users')

  return users.map(author => ({
    label: author.name + ' ' + author.lastname,
    value: author.id
  }))
}

export const getPaginatedUsers = async (_, { page, limit = 10, ids, name, roleIds, disciplineIds }) => {
  const params = {
    page,
    limit,
    name,
    roleIds,
    disciplineIds,
    ...(!!ids && { ids: ids.split(',').map(id => id.trim()) })
  }

  const usersResponse = await usersApi.get('/users/paginate', { params })

  return {
    data: {
      itemsTotal: usersResponse.data.itemsTotal,
      meta: {
        countActive: usersResponse.data.activeUsers,
        countInactive: usersResponse.data.inactiveUsers
      },
      totalPages: usersResponse.data.totalPages,
      items: usersResponse.data.items
    }
  }
}

export const getUserById = async (_, id) => {
  const { data: user } = await usersApi.get(`/users/${id}`)

  return user
}

export const getUsers = async () => {
  const { data: users } = await usersApi.get('/users')

  return users
}

export const createUser = async data => {
  const { file, ...payload } = data

  if (file) {
    payload.avatar_url = await sendFileToS3(file)
  }

  return usersApi.post('/users', payload)
}

export const updateUserById = async (id, data) => {
  const { file, ...payload } = data

  if (file) {
    payload.avatar_url = await sendFileToS3(file)
  }

  return usersApi.put(`/users/${id}/update`, payload)
}

export const deleteUserById = id => usersApi.delete(`/users/${id}`)

export const requestPasswordResetById = id => {
  const params = { id }

  return usersApi.get('/users/password/reset', { params })
}

export const createUserPassword = async payload => {
  return usersApi.post('/users/password', payload)
}

export const forgotPassword = async payload => {
  return usersNoAuthProvider.post('/users/password/forgot', payload)
}

export const verifyToken = async payload => {
  return usersNoAuthProvider.post('/users/token/validate', payload)
}

export const authenticate = payload => usersApi.post('/users/login', payload)

export const getUser = () => usersApi.get('/users/auth/me')

export const doLogout = () => usersApi.post('/users/logout')
