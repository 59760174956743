import axios from 'axios'
import { v4 as uuid } from 'uuid'
import awsPolicyApi from 'providers/awsPolicyProvider'

const getFileExtension = file => file.name.split('.').pop()

const getFileName = (file, fileExtension) => file.type.split('/')[0] + '_' + uuid() + '.' + fileExtension

export const sendFileToS3 = async file => {
  const { acl, aws_access_key, bucket_url, path, policy, signature } = await awsPolicyApi.get()

  const formData = new FormData()
  const fileExtension = getFileExtension(file)
  const filename = getFileName(file, fileExtension)
  formData.append('key', path + filename)
  formData.append('AWSAccessKeyId', aws_access_key)
  formData.append('acl', acl)
  formData.append('policy', policy)
  formData.append('signature', signature)
  formData.append('Content-Type', file.type || 'application/octet-stream')
  formData.append('file', file)
  formData.append('container', 'archiveUrl')

  const response = await axios.post(bucket_url, formData)

  return response.headers.location?.replace(/%2F/g, '/')
}

export const sendFileToS3V2 = async (file, provider, url) => {
  const awsData = await (await provider.get(`/${url}/aws-policy`)).data

  const fileExtension = getFileExtension(file)
  const filename = getFileName(file, fileExtension)
  const fileKey = `${awsData.path}${uuid()}/${filename}`

  const formData = new FormData()
  formData.append('key', fileKey)
  formData.append('policy', awsData.fields['Policy'])
  formData.append('x-amz-algorithm', awsData.fields['X-Amz-Algorithm'])
  formData.append('x-amz-credential', awsData.fields['X-Amz-Credential'])
  formData.append('x-amz-date', awsData.fields['X-Amz-Date'])
  formData.append('x-amz-signature', awsData.fields['X-Amz-Signature'])
  formData.append('bucket', awsData.fields['bucket'])
  formData.append('file', file)

  const response = await axios.post(awsData.url, formData)

  return {
    key: fileKey,
    response
  }
}
