export const config = {
  hml: {
    apiKey: 'AIzaSyANDPxGATNCsQe1W7IqVhrV6gs-OYDQ6Dk',
    authDomain: 'front-content.firebaseapp.com',
    projectId: 'front-content',
    storageBucket: 'front-content.appspot.com',
    messagingSenderId: '469455161643',
    appId: '1:469455161643:web:14483e0206a11237711d6e',
    measurementId: 'G-J0EPCB33EL'
  },
  prd: {
    apiKey: 'AIzaSyANDPxGATNCsQe1W7IqVhrV6gs-OYDQ6Dk',
    authDomain: 'front-content.firebaseapp.com',
    projectId: 'front-content',
    storageBucket: 'front-content.appspot.com',
    messagingSenderId: '469455161643',
    appId: '1:469455161643:web:94a7de4065882b23711d6e',
    measurementId: 'G-WSKM4KZNYW'
  }
}
