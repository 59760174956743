export const orderByKey = (array = [], key = 'order', order = 'asc') =>
  array.sort((a, b) => {
    if (order === 'asc') {
      return a[key] - b[key]
    }

    return b[key] - a[key]
  })

export const removeUndefinedValuesFromObject = object => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (value === undefined || value === '' || (Array.isArray(value) && !value.length) || value === {}) {
      return acc
    }

    return { ...acc, [key]: value }
  }, {})
}

export const arraySwap = (array, indexA, indexB) => {
  const tempEl = array[indexA]
  array[indexA] = array[indexB]
  array[indexB] = tempEl
}

export const removeDuplicates = array => [...new Set(array)]
