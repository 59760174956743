import axios from 'axios'

import { authRedirectInterceptor, userTokenInterceptor } from 'helpers'

const __API__ = process.env.REACT_APP_API_GATEWAY + '/basicResources/api'

const defaultOptions = {
  baseURL: __API__
}

const instance = axios.create(defaultOptions)

instance.interceptors.request.use(userTokenInterceptor, error => Promise.reject(error))

instance.interceptors.response.use(response => response.data, authRedirectInterceptor)

export default instance
