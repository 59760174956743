import React from 'react'
import { ThemeProvider as StyledComponentsProvider } from 'styled-components'
import { ThemeProvider, lexTheme } from '@conexia-az/az-components'

const Theme = ({ children }) => (
  <ThemeProvider theme={lexTheme}>
    <StyledComponentsProvider theme={lexTheme}>{children}</StyledComponentsProvider>
  </ThemeProvider>
)

export default Theme
