import React, { createContext, useContext, useState, useEffect } from 'react'

import { useUser } from './user-context'
import { getSchoolYears } from 'services/schoolYears'

const SchoolYearsContext = createContext()

const useSchoolYears = () => {
  const context = useContext(SchoolYearsContext)

  if (context === undefined) {
    throw new Error('useSchoolYears must be used within a SchoolYearsProvider')
  }

  return context
}

const SchoolYearsProvider = props => {
  const [schoolYears, setSchoolYears] = useState([])
  const [isFetchingSchoolYears, setIsFetchingSchoolYears] = useState(true)
  const { user } = useUser()

  useEffect(() => {
    const fetchSchoolYears = async () => {
      try {
        const yearsResponse = await getSchoolYears()
        setSchoolYears(yearsResponse)
      } catch (error) {
        // TODO: handle error
      } finally {
        setIsFetchingSchoolYears(false)
      }
    }

    if (user?.data?.id) {
      fetchSchoolYears()
    }
  }, [user?.data?.id])

  return <SchoolYearsContext.Provider value={{ schoolYears, isFetchingSchoolYears }} {...props} />
}

export { SchoolYearsProvider, useSchoolYears }
