import moment from 'moment'
import 'moment/locale/pt-br'
import filesize from 'filesize'

export const fromNow = date => moment(date).fromNow()

export const formatDate = date => moment(date).format('LLLL')

export const formatYouTubeDuration = duration => {
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60

  return `${minutes}m ${seconds}s`
}

export const formatDateToDdMmYyyy = date => moment(date).format('L')

export const limitString = (string, limit) => {
  if (!limit || !string || string.length <= limit) {
    return string
  }

  return `${string.slice(0, limit).trim()}...`
}

export const normalizeString = string =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

export const handleNumericInput = event => {
  const { value } = event.target
  event.target.value = value.replace(/[^0-9]/g, '')
}

export const formatIndexAlphabetically = index => String.fromCharCode(65 + index)

export const formatCounterNumber = (number = 0) => new Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(number)

export const formatFileSize = size => filesize(size)

export const toArray = item => {
  if (!item) {
    return []
  }
  return Array.isArray(item) ? item : [item]
}

export const indexToAnswer = index => (index + 10).toString(36).toUpperCase()

export const formatQuestionNumber = number => {
  return String(number).padStart(2, '0')
}
