import React, { createContext, useContext, useState, useEffect } from 'react'

import { useUser } from './user-context'
import { getGrades } from 'services/grades'

const GradesContext = createContext()

const useGrades = () => {
  const context = useContext(GradesContext)

  if (context === undefined) {
    throw new Error('useGrades must be used within a GradesProvider')
  }

  return context
}

const GradesProvider = props => {
  const [grades, setGrades] = useState([])
  const [isFetchingGrades, setIsFretchingGrades] = useState(true)
  const { user } = useUser()

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const gradesResponse = await getGrades()
        setGrades(gradesResponse)
      } catch (error) {
        // TODO: handle error
      } finally {
        setIsFretchingGrades(false)
      }
    }

    if (user?.data?.id) {
      fetchGrades()
    }
  }, [user?.data?.id])

  return <GradesContext.Provider value={{ grades, isFetchingGrades }} {...props} />
}

export { GradesProvider, useGrades }
