import React, { createContext, useCallback, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRemoteConfigBoolean } from 'reactfire'

import { authenticate, getUser, getUserPermissions, doLogout } from 'services/users'
import { TOKEN } from 'constants/token'
import { LOCATION } from 'constants/location'
import { removeObject } from 'helpers/storage'

const UserContext = createContext()

const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}

const remoteConfigKey = process.env.REACT_APP_REMOTE_ENV === 'dev' ? 'dev_use_questionsv3' : 'prd_use_questionsv3'

const UserProvider = props => {
  const location = useLocation()
  const { status: remoteConfigIsV3Status, data: remoteConfigIsV3 } = useRemoteConfigBoolean(remoteConfigKey)

  const [user, setUser] = useState(null)
  const [userInternal, setUserInternal] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true)

      const token = localStorage.getItem(TOKEN)
      if (token) {
        try {
          const user = await getUser(token)
          const permissions = await getUserPermissions(token)
          const userComplete = { ...user, permissions }
          setUserInternal(userComplete)
        } catch (error) {
          // TODO: handle error
        }
      }

      try {
        const user = await getUser(token)
        const permissions = await getUserPermissions(token)
        const userComplete = { ...user, permissions }
        setUser(userComplete)
      } catch (error) {
        // TODO: handle error
      } finally {
        setIsLoading(false)
      }
    }

    // As we already have a redirect to other login page when `me` api call fails, we are going to
    // prevent the api call if the user is trying to access these two pathnames in order to prevent
    // that redirect.
    if (['/criar-senha', '/login'].includes(location.pathname)) {
      return setIsLoading(false)
    }

    if (user === null || userInternal === null) fetchUser()
  }, [location.pathname])

  const login = useCallback(async credentials => {
    const response = await authenticate(credentials)

    localStorage.setItem(TOKEN, response.data.access_token)
    const user = await getUser(response.data)
    const permissions = await getUserPermissions(response.data)
    const userComplete = { ...user, permissions }

    setUser(userComplete)
    setUserInternal(userComplete)
  }, [])

  const logout = useCallback(async () => {
    await doLogout()
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(LOCATION)
    removeObject('questionFilters')
    setUser(null)
    setUserInternal(null)
    window.location.href = `${process.env.PUBLIC_URL}/login`
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userInternal,
        login,
        logout,
        isLoading,
        remoteConfigIsV3Status,
        remoteConfigIsV3
      }}
      {...props}
    />
  )
}

export { UserProvider, useUser }
